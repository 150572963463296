import { ref } from "vue";
import { useStore } from "vuex";
import {
  EntityProps,
  ApprovalProps,
} from "@/views/Payment/Mgmt/PendingApproval/PayoutRound/type";
import {
  fetchPaymentAprovalPendingList,
  getDealerPendingList,
  getDealerTrackingList,
  fetchPaymentAprovalTrackingList,
  getDealer2RoundDetailForPeding,
} from "@/API/payment";
import { getDealer2RoundDetail } from "@/API/payment";
import axios from "axios";
// axios.defaults.baseURL = "";
const MOCK: {
  content: any[];
  payoutRound: string;
  vatheader: any[];
} = {
  content: [
    {
      entity: "MBCL",
      normalPayment: [
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "MB",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "AMG",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "EQ",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "subtotal",
          color: "green",
          periodwoVat: "12000",
        },
      ],
      rePayment: [
        {
          businessUnit: "MB",
          color: "green",
          periodwoVat: "12000",
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "AMG",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "EQ",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "subtotal",
          color: "green",
          periodwoVat: "12000",
        },
      ],
      totalNormal: {
        payoutAmountWVat: "120000",
        payoutAmountWoVat: "12000",
      },
      totalRepayment: {
        payoutAmountWVat: "120000",
        payoutAmountWoVat: "12000",
      },
    },
    {
      entity: "BBAC",
      normalPayment: [
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "MB",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "AMG",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "EQ",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "subtotal",
          color: "green",
          periodwoVat: "12000",
        },
      ],
      rePayment: [
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "MB",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "AMG",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "EQ",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "subtotal",
          color: "green",
          periodwoVat: "12000",
        },
      ],
      totalNormal: {
        payoutAmountWVat: "120000",
        payoutAmountWoVat: "12000",
      },
      totalRepayment: {
        payoutAmountWVat: "120000",
        payoutAmountWoVat: "12000",
      },
    },
    {
      entity: "FBAC",
      normalPayment: [
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "MB",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "AMG",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "EQ",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "subtotal",
          color: "green",
          periodwoVat: "12000",
        },
      ],
      rePayment: [
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "MB",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "AMG",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "EQ",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "subtotal",
          color: "green",
          periodwoVat: "12000",
        },
      ],
      totalNormal: {
        payoutAmountWVat: "120000",
        payoutAmountWoVat: "12000",
      },
      totalRepayment: {
        payoutAmountWVat: "120000",
        payoutAmountWoVat: "12000",
      },
    },
    {
      entity: "DENZA",
      normalPayment: [
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "MB",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "AMG",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "EQ",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "subtotal",
          color: "green",
          periodwoVat: "12000",
        },
      ],
      rePayment: [
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "MB",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "AMG",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "EQ",
          color: "green",
          periodwoVat: "12000",
        },
        {
          "2018 w/o VAT": "12000",
          "2019 w/o VAT": "12000",
          "2020 w/o VAT": "12000",
          businessUnit: "subtotal",
          color: "green",
          periodwoVat: "12000",
        },
      ],
      totalNormal: {
        payoutAmountWVat: "120000",
        payoutAmountWoVat: "12000",
      },
      totalRepayment: {
        payoutAmountWVat: "120000",
        payoutAmountWoVat: "12000",
      },
    },
  ],
  payoutRound: "2021_Apr_R1",
  vatheader: ["2018 w/o VAT", "2019 w/o VAT", "2020 w/o VAT"],
};
const originColumns = [
  {
    title: "",
    dataIndex: "color",
    width: 20,
    align: "center",
    slots: { customRender: "status" },
  },
  {
    title: "Business Unit",
    dataIndex: "businessUnit",
    slots: { customRender: "bold" },
    width: 150,
  },
  {
    title: "Period Total w/o VAT",
    dataIndex: "periodwoVat",
    align: "right",
    slots: { customRender: "formatThousand" },
  },
];
function unique(arr: string[]) {
  const arr1: string[] = [];
  for (let i = 0; i < arr.length; i++) {
    if (!arr1.includes(arr[i])) {
      // 检索arr1中是否含有arr中的值
      arr1.push(arr[i]);
    }
  }
  return arr1;
}
const useDealerPayout = (payoutRound?: string) => {
  // 折叠面板数据
  const pageData = ref<EntityProps[]>([]);
  const payoutCodes = ref<string[]>([]);
  const getData = (id: string, type?: string) => {
    if (!id) {
      return false;
    }
    if (type === "pendingApproval") {
      getDealer2RoundDetailForPeding(id).then((res: any) => {
        const dynamicColumns = res.vatheader.map((item: string) => {
          return {
            title: item,
            dataIndex: item,
            align: "right",
            slots: { customRender: "formatThousand" },
          };
        });
        res.content.forEach((entity: EntityProps) => {
          entity.columns = originColumns.concat(dynamicColumns);
        });
        pageData.value = res.content;
        payoutCodes.value = res.payoutCode;
      });
    } else {
      getDealer2RoundDetail(id).then((res: any) => {
        const dynamicColumns = res.vatheader.map((item: string) => {
          return {
            title: item,
            dataIndex: item,
            align: "right",
            slots: { customRender: "formatThousand" },
          };
        });
        res.content.forEach((entity: EntityProps) => {
          entity.columns = originColumns.concat(dynamicColumns);
        });
        pageData.value = res.content;
      });
    }

    console.log("pageData", pageData.value);
  };
  // payoutlist 下拉列表
  const store = useStore();
  const userName = store.state.user.fulnm;
  const payoutList = ref<string[]>([]);
  const tableList = ref<any[]>([]);
  // 获取pending的下拉列表
  const getPayoutList = () => {
    getDealerPendingList({
      payoutRound,
      pageNumber: 1,
      pageSize: 10000,
      userId: store.state.user.usrid,
      typeList: [],
    }).then((data: any) => {
      payoutList.value = unique(
        data.content.map((item: any) => {
          return item.payoutRound;
        })
      );
      tableList.value = data.content;
    });
  };
  // 获取tracking下拉列表
  const payoutTrackingList = ref<string[]>([]);
  const getPayoutTrackingList = () => {
    const params = {
      year: "",
      month: "",
      payoutCode: "",
      pageNumber: 1,
      pageSize: 10000,
      userId: store.state.user.usrid,
      payoutRound: "",
      typeList: [],
    };
    getDealerTrackingList(params).then((data: any) => {
      payoutTrackingList.value = unique(
        data.content.map((item: any) => {
          return item.payoutRound;
        })
      );
    });
    // fetchPaymentAprovalTrackingList({
    //   month: "",
    //   pageNumber: 1,
    //   pageSize: 10000,
    //   payoutCode: "",
    //   userName,
    //   status: "DEALER",
    //   year: "",
    // }).then((data) => {
    //   payoutTrackingList.value = unique(
    //     data.content.map((item: any) => {
    //       return item.payoutRound;
    //     })
    //   );
    // });
  };
  // 斑马纹
  const getRowClass = (record: ApprovalProps, index: number) => {
    if (index % 2 === 1) {
      return "table-striped";
    } else {
      return null;
    }
  };
  return {
    getData,
    pageData,
    payoutList,
    payoutTrackingList,
    tableList,
    payoutCodes,
    getPayoutList,
    getPayoutTrackingList,
    getRowClass,
  };
};
export default useDealerPayout;
