
import { defineComponent, watch, ref } from "vue";
import { RightOutlined } from "@ant-design/icons-vue";
// import { toThousands } from "@/utils/approve/index";
import { toThousandsAndTwoDecimal } from "@/utils/payment/numberFormat";
import useDealerPayout from "@/hooks/payment/Mgmt/PendingApproval/PayoutRound/useDealerPayout";
import { DataProps } from "@/views/Payment/Mgmt/PendingApproval/PayoutRound/type";
export default defineComponent({
  name: "entity",
  props: {
    payoutRound: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "pendingApproval",
    },
  },
  setup(props) {
    const { getData, pageData } = useDealerPayout();
    getData(props.payoutRound, props.type);
    const fetchData = () => {
      getData(props.payoutRound, props.type);
    };
    const refreshData = () => {
      getData(props.payoutRound, props.type);
    };
    watch(
      () => props.payoutRound,
      (newValue: string) => {
        getData(newValue, props.type);
      }
    );
    const getRowClass = (data: DataProps[]) => {
      return (record: DataProps, index: number) => {
        if (data && index + 1 === data.length) {
          return "table-total";
        } else {
          if (index % 2 === 1) {
            return "table-striped";
          } else {
            return null;
          }
        }
      };
    };
    const activeKey = ref(["0"]);
    return {
      pageData,
      getRowClass,
      activeKey,
      toThousandsAndTwoDecimal,
      fetchData,
      refreshData,
    };
  },
  components: {
    RightOutlined,
  },
});
